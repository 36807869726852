body {
  margin: 0;
  font-weight: 400;
  font-family: "Varela Round", sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
  background: #3c6997;
  border-bottom: 5px solid #5adbff;
}
.container::after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}

nav li {
  display: inline-block;
  margin-left: 60px;
  margin-right: 30px;
  padding-top: 7%;
  padding-bottom: 7%;
  position: relative;
}

nav a {
  color: #5adbff;
  text-decoration: none;
  text-transform: uppercase;
}

nav a:hover {
  color: #ffdd4a;
}

nav a::before {
  content: "";
  display: block;
  height: 5px;
  background-color: #ffdd4a;

  position: absolute;
  top: 0;
  width: 0%;

  transition: all ease-in-out 250ms;
}

nav a:hover::before {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  nav ul {
    display: flex;
    margin-right: 14%;
  }

  /* nav li {
    margin-left: 20vw;
    margin-right: 60px;
    padding-top: 4%;
    padding-bottom: 4%;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
  }
}
