.Home {
  /* padding-bottom: 40px; */
  background-color: #094074;
  font-family: "Varela Round", sans-serif;
}

.Home .welcome-box {
  background-color: #5adbff;
  height: 40vh;
}

.Home .welcome-box .fa-angle-down {
  color: cornsilk;
  font-size: 30px;
}

.Home .welcome-box h1 {
  padding-top: 2%;
  margin: 0;
  color: cornsilk;
}

.Home .welcome-box p {
  padding-left: 10%;
  padding-right: 10%;
}

.Home ul {
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.Home .project-item {
  padding-top: 20px;
  padding-bottom: 60px;
}

.Home li a {
  display: inline-block;
}

.Home li p,
h3 {
  display: inline-block;
  width: 80vw;
  color: cornsilk;
  font-size: 19px;
}

.Home h2,
a {
  color: cornsilk;
  text-decoration: none;
  overflow: hidden;
}

.Home h2:hover {
  color: #ffdd4a;
}

.Home img {
  width: 100%;
}

.Home .img-tag {
  margin-left: 8%;
  width: 80vw;
  display: block;
  border: 8px ridge cornsilk;
  border-radius: 5px;
}

.Home .tech-list {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.Home .tech-item {
  margin: 10px;
  width: 6%;
}

.Home .css-logo {
  width: 4.4%;
}

.Home .ts-logo {
  width: 5.6%;
}

@media only screen and (max-width: 768px) {
  .Home .project-container {
    padding-left: 0;
  }

  .Home .project-item {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .Home h2,
  h3 {
    font-size: 15px;
  }

  .Home .img-tag {
    margin-left: 10%;
    border: 2px ridge cornsilk;
  }

  .Home li p {
    font-size: 11px;
  }

  .Home .tech-item {
    margin: 10px;
    width: 12%;
  }

  .Home .css-logo {
    width: 8.9%;
  }

  .Home .ts-logo {
    width: 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Home li p {
    font-size: 16px;
  }

  .Home .tech-item {
    margin: 10px;
    width: 9%;
  }

  .Home .css-logo {
    width: 6.9%;
  }

  .Home .ts-logo {
    width: 9%;
  }
}
