/* * {
  outline: 1px solid red;
} */

.About {
  background-color: #094074;
}

.About h2,
p {
  color: cornsilk;
}

.About h2 {
  margin-top: 0;
  padding-top: 20px;
}

.About img {
  padding-top: 50px;
  width: 20%;
}

.About p {
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 66vw;
  padding-bottom: 2%;
  border-bottom: 5px groove cornsilk;
}

.About .logos {
  width: 10%;
  padding: 20px 25px 5px 25px;
}

@media only screen and (max-width: 768px) {
  .About {
    padding-top: 5%;
    padding-bottom: 45px;
  }

  .About img {
    padding-top: 0;
    width: 50%;
  }

  .About h2 {
    padding-top: 45px;
    font-size: 20px;
  }

  .About p {
    font-size: 14px;
    width: 80vw;
  }

  .About .logos {
    width: 20%;
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .About {
    padding-top: 5%;
    height: 100vh;
  }

  .About img {
    width: 40%;
  }

  .About .logos {
    width: 17%;
  }

  .About p {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 76vw;
    padding-bottom: 2%;
    border-bottom: 5px groove cornsilk;
  }
}
