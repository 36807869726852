.App {
  text-align: center;
}

.collapsible .header {
  background-color: #5adbff;
  color: cornsilk;
  font-size: 30px;
  padding: 6px;
  cursor: pointer;
}

.collapsible .content {
  padding: 6px;
  background-color: #5adbff;
  height: 25vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1%;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .collapsible .header {
    font-size: 20px;
  }

  .collapsible .content {
    font-size: 14px;
    height: 30vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .collapsible .header {
    font-size: 20px;
  }

  .collapsible .content {
    font-size: 14px;
    height: 20vh;
  }
}
